import React, { useEffect, useState } from "react";
import axios from "axios";
import "./assets/css/App.css";
import ProductOutlet from "./components/ProductOutlet";
import FooterArea from "./components/FooterArea";
import Register from "./components/Register";
import InfoBox from "./components/BoxInfo";
import WhatsAppButton from "./components/WhatsAppButton";
import * as XLSX from "xlsx";
import LogoGallery from "./components/LogoGallery";
import HeaderOutlet from "./components/HeaderOutlet";
import OutletBanner from "./components/OutletBanner";
import SlidingTextVideoComponent from "./components/SlidingTextVideo";
import HomeSlidingTextVideoComponent from "./components/HomeSlidingTextVideo";
import ExcelButton from "./components/ExcelWrapper";
import ApplyButton from "./components/ApplyButton";

function Outlet() {
  const [dataA, setDataA] = useState([]);
  const [dataB, setDataB] = useState([]);
  const [responseData, setResponseData] = useState([]);

  const brandOrder = [
    "Alcatel",
    "Apple",
    "Casper",
    "Huawei",
    "Oppo",
    "Poco",
    "RealMe",
    "Reeder",
    "Samsung",
    "Tecno",
    "Vivo",
    "Xiaomi",
  ];

  const sortBrands = (data) => {
    if (!Array.isArray(data)) return [];

    const brandOrderMap = brandOrder.reduce((acc, brand, index) => {
      acc[brand.toLowerCase().trim()] = index;
      return acc;
    }, {});

    return data.sort((a, b) => {
      const aBrand = a.brand_title?.toLowerCase().trim() || "";
      const bBrand = b.brand_title?.toLowerCase().trim() || "";

      const aIndex =
        brandOrderMap[aBrand] !== undefined ? brandOrderMap[aBrand] : Infinity;
      const bIndex =
        brandOrderMap[bBrand] !== undefined ? brandOrderMap[bBrand] : Infinity;

      return aIndex - bIndex;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://omega.deltaservis.com.tr/_api/queue/api.php?opt=salesStockPricesOutlet"
        );

        if (response.data) {
          const responseData = response.data;
          setResponseData(response.data);
          const sortedData = sortBrands(Object.keys(responseData));

          const middlePoint = Math.ceil(sortedData.length / 6);
          const first = sortedData.slice(0, middlePoint);
          const secondary = sortedData.slice(middlePoint);

          setDataA(first);
          setDataB(secondary);

          const brandForms = sortedData.map((brandKey) => {
            const brand = responseData[brandKey];
            console.log(brandKey);
            return {
              brand_title: brandKey,
              forms: brand.forms.map((form) => ({
                form_id: form.form_id,
                outlet_answers: form.outlet_answers,
                variant_title: form.variant_title,
                model_name: form.model_name,
                memory: form.memory,
                new_memory: form.new_memory,
                grade_title: form.grade_title,
                color: form.color,
                video_url: form.video_url,
                price: form.price,
                retail_price: form.retail_price,
              })),
            };
          });
          // brandForms'i dataA ve dataB ile güncelleme
          const firstForms = brandForms.filter(
            (form, index) => index < middlePoint
          );
          const secondForms = brandForms.filter(
            (form, index) => index >= middlePoint
          );

          // brandForms verisini dataA ve dataB'ye atama
          setDataA(firstForms);
          setDataB(secondForms);
        } else {
          console.error("Brands verisi bulunamadı.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // function exportToOutlet() {
  //   try {
  //     if (!responseData || Object.keys(responseData).length === 0) {
  //       console.error("Veri bulunamadı. Excel'e aktarma işlemi yapılamıyor.");
  //       return;
  //     }

  //     const flattenedData = Object.keys(responseData).flatMap((brandKey) => {
  //       const brand = responseData[brandKey];
  //       return brand.forms.map((form) => ({
  //         Marka: brandKey,
  //         Model: form.variant_title,
  //         Hafıza: form.memory,
  //         Renk: form.color,
  //         OutletNedeni: form.outlet_answers,
  //         Fiyat: form.price,
  //         Video: form.video_url,
  //       }));
  //     });

  //     const worksheet = XLSX.utils.json_to_sheet(flattenedData);

  //     worksheet["!cols"] = [
  //       { width: 20 },
  //       { width: 50 },
  //       { width: 15 },
  //       { width: 15 },
  //       { width: 20 },
  //       { width: 15 },
  //     ];

  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

  //     const today = new Date();
  //     const dateString = today.toISOString().split("T")[0];
  //     XLSX.writeFile(workbook, `outletFiyatları_${dateString}.xlsx`);
  //   } catch (error) {
  //     console.error("Excel'e aktarma işlemi sırasında hata oluştu:", error);
  //   }
  // }

  return (
    <>
      <HeaderOutlet />
      <OutletBanner />
      <SlidingTextVideoComponent />
      <LogoGallery />

      <div
        id="urunler"
        className="container-fluid custom-container mobile-p-0 mobile-m-0 pt-5"
      >
        {dataA.length === 0 && dataB.length === 0 ? (
          <div className="text-danger text-center my-3">
            Stok bilgisi bulunamadı!
          </div>
        ) : (
          <div>
            <div className="w-[90%] mx-auto m-0" id="urunler">
              <div className="col-md-12 product-list pt-5 mobile-p-0 mobile-m-0">
                <ProductOutlet data={dataA} />
              </div>
            </div>

            <div>
              <HomeSlidingTextVideoComponent />
            </div>

            <div className="w-[90%] mx-auto m-0">
              <div className="col-md-12 product-list pt-5 mobile-p-0 mobile-m-0">
                <ProductOutlet data={dataB} />
              </div>
            </div>
          </div>
        )}
        <InfoBox />
        <Register />
        <FooterArea />
        <ExcelButton  />

        <ApplyButton  />
        <WhatsAppButton />
      </div>
    </>
  );
}

export default Outlet;
