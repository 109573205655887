import React, { useEffect, useState } from "react";
import axios from "axios";
import gsap from "gsap";
import "./assets/css/App.css";
import ProductList from "./components/ProductList";
import FooterArea from "./components/FooterArea";
import ProductInfoTable from "./components/ProductInfoTable";
import Register from "./components/Register";
import InfoBox from "./components/BoxInfo";
import WhatsAppButton from "./components/WhatsAppButton";
import HeroSection from "./components/HeroSection";
import SlidingTextVideoComponent from "./components/SlidingTextVideo";
import HeaderHome from "./components/HeaderHome";
import HomeSlidingTextVideoComponent from "./components/HomeSlidingTextVideo";
import ExcelButton from "./components/ExcelWrapper";
import ApplyButton from "./components/ApplyButton";

function Home() {
  const [dataA, setDataA] = useState([]);
  const [dataB, setDataB] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [responseData, setResponseData] = useState([]);

  const brandOrder = [
    "Apple",
    "Oppo",
    "Vivo",
    "Tecno",
    "Samsung",
    "Huawei",
    "Tcl",
    "Xiaomi",
    "Poco",
    "Realme",
    "OnePlus",
  ];

  const sortBrands = (data) => {
    const brandOrderMap = brandOrder.reduce((acc, brand, index) => {
      acc[brand.toLowerCase().trim()] = index; // Küçük harfe çevirme ve boşlukları temizleme
      return acc;
    }, {});

    return data.sort((a, b) => {
      const aBrand = a.brand_title.toLowerCase().trim();
      const bBrand = b.brand_title.toLowerCase().trim();

      const aIndex =
        brandOrderMap[aBrand] !== undefined ? brandOrderMap[aBrand] : Infinity;
      const bIndex =
        brandOrderMap[bBrand] !== undefined ? brandOrderMap[bBrand] : Infinity;

      return aIndex - bIndex;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://omega.deltaservis.com.tr/_api/queue/api.php?opt=salesStockPrices"
        );

        const responseData = response.data;
        setResponseData(responseData); // JSON verisi kaydedildi
        const sortedData = sortBrands(responseData);
        const middlePoint = Math.ceil(sortedData.length / 6);
        const first = sortedData.slice(0, middlePoint + 2);
        const secondary = sortedData.slice(middlePoint + 2);

        setDataA(first);
        setDataB(secondary);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const tl = gsap.timeline({ repeat: 1 });
    tl.to(".slideText", { backgroundPosition: "-960px 0", duration: 40 });

    fetchData();
  }, []);

  return (
    <>
      <HeroSection />
      <div
        id="main"
        className="container-fluid custom-container mobile-p-0 mobile-m-0"
      >
        <HeaderHome />
        <SlidingTextVideoComponent />

        <div className="row p-4">
          <div className="col-md-4">
            <div className="card-custom box1">
              <div className="m-auto">
                <h2 className="title-2 d-block">12 Ay Taksit</h2>
                <h2 className="title-2 d-block">İmkanı</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-custom box2">
              <div className="m-auto">
                <h2 className="title-2 d-block">Tek Çekim</h2>
                <h2 className="title-2 d-block">0 Komisyon</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-custom box3">
              <div className="m-auto">
                <h2 className="title-2 d-block">Ücretsiz</h2>
                <h2 className="title-2 d-block">Kargo</h2>
              </div>
            </div>
          </div>
        </div>
        {dataA && dataB ? (
          <div className="row pt-5 m-0" id="urunler">
            <div className="col-md-6 product-list pt-5 mobile-p-0 mobile-m-0">
              <ProductList
                data={dataA}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
              />
            </div>
            <div className="col-md-6 product-list pt-5 mobile-p-0 mobile-m-0">
              <ProductList
                data={dataB}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
              />
            </div>
          </div>
        ) : (
          <div className="text-danger text-center my-3">
            Stok bilgisi bulunamadı! Lütfen daha sonra tekrar deneyiniz.
          </div>
        )}
        <HomeSlidingTextVideoComponent />
        <ProductInfoTable />
        <InfoBox />
        <Register />
        <FooterArea />
        <ExcelButton  />

        <ApplyButton  />
        <WhatsAppButton />
      </div>
    </>
  );
}

export default Home;
