import React, { useState } from "react";
import { Bitrix24Form } from "react-bitrix24-form";
import { BiMessageAltEdit } from "react-icons/bi";

const ApplyButton = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const loadScript = () => {
    if (!isScriptLoaded) {
      const script = document.createElement("script");
      script.src =
        "https://gama.deltaservis.com.tr/upload/crm/form/loader_13_s339hw.js";
      script.async = true;
      script.onload = () => {
        setIsModalOpen(true);
      };
      document.head.appendChild(script);
      setIsScriptLoaded(true);
    } else {
      setIsModalOpen(true);
    }
  };
  return (
    <div>
      <Bitrix24Form
        src="https://cdn.bitrix24.com/b16700425/crm/form/loader_55.js"
        data="click/13/s339hw"
      />
      <div className="apply-wrapper" onClick={loadScript}>
        <BiMessageAltEdit className="svg-apply " />
      </div>
    </div>
  );
};

export default ApplyButton;
