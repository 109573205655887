import React from "react";
import { Accordion } from "react-bootstrap";
import colors from "../assets/utilities/colors";
import appleLogo from "../assets/images/apple-logo.png";
import realmeLogo from "../assets/images/realme-logo.png";
import samsungLogo from "../assets/images/samsung-logo.png";
import pocoLogo from "../assets/images/poco-logo.png";
import xiaomiLogo from "../assets/images/xiaomi-logo.png";
import huaweiLogo from "../assets/images/huawei-logo.png";
import oppoLogo from "../assets/images/oppo-logo.png";
import vivoLogo from "../assets/images/vivo-logo.png";
import tecnoLogo from "../assets/images/tecno-logo.png";
import oneplusLogo from "../assets/images/oneplus-logo.png";
import omixLogo from "../assets/images/omix-logo.png";
import tclLogo from "../assets/images/tcl-logo.png";
import reederLogo from "../assets/images/reeder-logo.png";

const images = {
  Apple: appleLogo,
  RealMe: realmeLogo,
  Samsung: samsungLogo,
  Poco: pocoLogo,
  Xiaomi: xiaomiLogo,
  Huawei: huaweiLogo,
  Oppo: oppoLogo,
  Vivo: vivoLogo,
  Tecno: tecnoLogo,
  OnePlus: oneplusLogo,
  Omix: omixLogo,
  TCL: tclLogo,
  Reeder: reederLogo,
};

const GradeItem = ({ grade }) => {
  const totalStock = grade.variants.reduce((accumulator, variant) => {
    return accumulator + parseInt(variant.stock);
  }, 0);

  const stockMessage = totalStock < 5 ? "Tükeniyor" : "Stokta";

  return (
    <div className="item-wrapper w-100">
      <span className="item-name">{grade.grade_title}</span>
      <div className="d-flex align-items-center">
        {grade.variants.map((variant, index) => (
          <span
            key={index}
            className="color-circle"
            style={{ backgroundColor: colors[variant.color] }}
          ></span>
        ))}
        <span className="price">
          <div>{grade.price}</div>
        </span>
        <div
          className={`m-1 mx-2 accordion-item alert ${
            totalStock < 5 ? "alert-orange" : "alert-success"
          }`}
        >
          {stockMessage}
        </div>
      </div>
    </div>
  );
};

const ModelItem = ({ model, modelIndex, activeKey, setActiveKey }) => {
  const grades = [
    { title: "İyi", price: "-" },
    { title: "Çok İyi", price: "-" },
    { title: "Mükemmel", price: "-" },
  ];

  model.grades.forEach((grade) => {
    const gradeIndex = grades.findIndex((g) => g.title === grade.grade_title);
    if (gradeIndex !== -1) {
      grades[gradeIndex].price = grade.price;
    }
  });

  const isActive = activeKey === modelIndex;

  return (
    <Accordion activeKey={isActive ? modelIndex : null}>
      <Accordion.Item eventKey={modelIndex}>
        <Accordion.Header
          onClick={() => setActiveKey(isActive ? null : modelIndex)}
        >
          <div className="accordion-button item-wrapper w-100" type="button">
            <span className="item-name">{model.model_name}</span>
            <div className="d-flex">
              {grades.map((grade, index) => (
                <div key={index} className="d-flex align-items-center">
                  <div
                    className={`mb-0 custom-alert alert mx-1 ${
                      grade.title === "İyi"
                        ? "alert-secondary"
                        : grade.title === "Çok İyi"
                        ? "alert-warning border-x-side"
                        : "alert-primary"
                    }`}
                  >
                    <div>{grade.price}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {model.grades.map((grade, index) => (
            <GradeItem key={index} grade={grade} />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const CategoryItem = ({ category, activeKey, setActiveKey }) => {
  const imageUrl = images[category.brand_title];
  return (
    <div className="main-card card custom-bg" id={category.brand_title}>
      <div>
        <div className="card-header">
          <div className="card-title m-0">
            {imageUrl ? (
              <img
                className={category.brand_title}
                alt={category.brand_title}
                src={imageUrl}
                A
              ></img>
            ) : (
              <h4 className="m-0 d-inline">{category.brand_title}</h4>
            )}
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-right">
                <div className="mb-0 alert mx-1 alert-good">
                  <div>İyi</div>
                </div>
              </div>
              <div className="d-flex align-items-right">
                <div className="mb-0 alert mx-1 alert-very-good">
                  <div>Çok İyi</div>
                </div>
              </div>
              <div className="d-flex align-items-right">
                <div className="mb-0 alert mx-1 alert-perfect">
                  <div>Mükemmel</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <ul>
          {category.top_models.map((topModel, topModelIndex) => (
            <React.Fragment key={topModelIndex}>
              <div className="d-none">{topModel.top_model}</div>
              {topModel.models.map((model, modelIndex) => (
                <ModelItem
                  key={modelIndex}
                  model={model}
                  modelIndex={`${category.brand_title}-${topModelIndex}-${modelIndex}`}
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                />
              ))}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ProductList = ({ data, activeKey, setActiveKey }) => {
  return (
    <>
      {data.map((category, categoryIndex) => (
        <CategoryItem
          key={categoryIndex}
          category={category}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
      ))}
    </>
  );
};

export default ProductList;
