const colors = {
  "Space Grey": "#24292b",
  White: "#FFFFFF",
  Burgundy: "#800020",
  Grey: "#808080",
  "Dark Silver": "#A9A9A9",
  Silver: "#C0C0C0",
  "Karısık çok Renkli": "#FFFFFF",
  Lacivert: "#00008B",
  Blue: "#0000FF",
  Black: "#000000",
  Gold: "#FFD700",
  Pink: "#FFC0CB",
  Red: "#FF0000",
  Yellow: "#FFFF00",
  Purple: "#800080",
  Green: "#008000",
  Turuncu: "#FFA500",
  "Renk Belirtilmemis": "#FFFFFF",
  Titanium: "#878681",
  Copper: "#B87333",
  Brown: "#A52A2A",
  Bronze: "#CD7F32",
  "Rose Gold": "#B76E79",
  Rose: "#FF007F",
  Peach: "#FFE5B4",
  Violet: "#EE82EE",
  Oranga: "#FFA500",
  Orange: "#FFA500",
  "Dark Blue": "#00008B",
  "Jet Black": "#0B0B0B",
  "Midnight Black": "#191970",
  "Light Blue": "#ADD8E6",
  "Lavender Grey": "#BDBBD7",
  Coral: "#FF7F50",
  Starlight: "#E4E5E6",
  Beige: "#F5F5DC",
  Midnight: "#191970",
  "Mystic Bronze": "#D3AF37",
};

export default colors;
