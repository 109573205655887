import { useEffect } from "react";
import { Link } from "react-scroll";
import "./SlidingTextVideo/SlidingTextVideo.css";

const SlidingTextVideoComponent = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {}, 500);
    }, 5000); // Change slogan every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="outer-box hidden lg:block ">
      <div className="sliding-containers  ">
        <div className="left-section">
          <div className="bg-gradient-to-r from-orange-300 to-red-500 bg-clip-text text-5xl font-bold text-transparent ">
            <span className="line-height">   Her Detayı İzle, <br /> Kazançlı Alışverişin Tadını Çıkar!</span>
          </div>
        </div>
        <div className="right-sections">
          <video width="60%" controls autoPlay loop muted>
            <source src="/outletvideoları.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default SlidingTextVideoComponent;
