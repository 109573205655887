import { Link } from "react-scroll";

const OutletBanner = () => {
  return (
    <div className="relative isolate bg-gray-950 px-3 sm:pt-24 lg:px-4 overflow-hidden">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#b71e5e] to-[#1809e8] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div className="mx-auto max-w-2xl py-32 lg:py-16 sm:py-32">
        <div className="text-center ">
          <h1 className="text-balance text-5xl font-semibold tracking-tight text-white sm:text-7xl">
            Outlet{" "}
            <span className="bg-gradient-to-r from-blue-600 to-red-700 bg-clip-text text-transparent">
              Ürünlerle
            </span>
          </h1>

          <h1 className="text-balance text-5xl font-semibold tracking-tight text-white sm:text-7xl">
            Avantajları Keşfet
          </h1>
          <p className="mt-8 text-pretty text-lg font-medium text-white sm:text-xl/8">
            Fiyat ve performansın buluşma noktası! Outlet ürünlerimizle yüksek
            kaliteye uygun fiyatlarla sahip olun. Her ürünün özel videosunu
            izleyerek satın almadan önce tüm detayları keşfedin!
            {/* <span className="text-black-900 font-bold">Delta Servis</span>
            ’in güçlü iş ortaklıkları sayesinde rakiplerinizin bir adım önünde
            olma fırsatını yakalayın. Siz de kazançlı bir yolculuk için Delta
            ailesinde yerinizi alın! */}
          </p>

          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="urunler"
              smooth={true}
              duration={1000}
              className="rounded-md bg-red-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-800 cursor-pointer"
            >
              Hemen İnceleyin
            </Link>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#590f2e] to-[#2a20b9] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
};
export default OutletBanner;
